.sync-modal {
  min-width: 350px;
  color: #495057;

  .modal-content {
    padding: 50px;
  }

  h1 {
    font-size: 2rem;
  }
}
